import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import { combineReducers } from "redux";
import menuReducer from "./menuSlice";
import accountReducer from "./accountSlice";
import merchantReducer from "./merchantSlice";
import sitesDemoReducer from "./sitesDemoSlice";

// Logger middleware configuration
const logger = createLogger({
    collapsed: true, // Collapse action logs for readability
    diff: true, // Show state diff
});

// Persist configuration
const persistConfig = {
    key: "root",
    storage,
    whitelist: [
        "merchantShop",
        "sitesDemo",
        "account",
    ],
};

// Combine reducers
const rootReducer = combineReducers({
    restaurant: menuReducer,
    merchantShop: merchantReducer,
    sitesDemo: sitesDemoReducer,
    account: accountReducer,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        // Default middleware configuration
        const middleware = getDefaultMiddleware({
            serializableCheck: false, // Required for redux-persist
        });

        // Conditionally add logger middleware in development
        if (process.env.NODE_ENV === 'development') {
            middleware.push(logger);
        }

        return middleware;
    },
});

// Create the persistor
export const persistor = persistStore(store);

/*
UNCOMMENT TO PURGE REDUX
*/
// persistor.purge();

export default store;
