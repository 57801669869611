exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-homes-js": () => import("./../../../src/pages/homes.js" /* webpackChunkName: "component---src-pages-homes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-records-index-js": () => import("./../../../src/pages/records/index.js" /* webpackChunkName: "component---src-pages-records-index-js" */),
  "component---src-pages-records-services-js": () => import("./../../../src/pages/records/services.js" /* webpackChunkName: "component---src-pages-records-services-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-sites-demo-js": () => import("./../../../src/pages/sites/demo.js" /* webpackChunkName: "component---src-pages-sites-demo-js" */),
  "component---src-pages-sites-index-js": () => import("./../../../src/pages/sites/index.js" /* webpackChunkName: "component---src-pages-sites-index-js" */),
  "component---src-pages-sites-menupdf-js": () => import("./../../../src/pages/sites/menupdf.js" /* webpackChunkName: "component---src-pages-sites-menupdf-js" */)
}

