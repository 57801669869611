import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cart: [],
    storeItems: [],
    isCartVisible: false,
    merchantMode: true,
};
const merchantSlice = createSlice({
    name: 'merchantShop',
    initialState,
    reducers: {
        toggleItemInCart: (state, action) => {
            const storeItem = state.storeItems.find((item) => item.id === action.payload.id);

            if (!storeItem || storeItem.stock <= 0) {
                return; // Prevent adding if item is out of stock
            }

            const itemIndex = state.cart.findIndex((item) => item.id === action.payload.id);

            if (itemIndex === -1) {
                state.cart.push({ ...action.payload, quantity: 1 });
            } else {
                state.cart.splice(itemIndex, 1);
            }
        },
        updateItemQuantity: (state, action) => {
            const { id, quantity } = action.payload;
            const storeItem = state.storeItems.find((item) => item.id === id);

            if (!storeItem) {
                return; // Prevent update if item doesn't exist
            }

            const itemIndex = state.cart.findIndex((item) => item.id === id);

            if (itemIndex !== -1 && quantity > 0) {
                const maxQuantity = Math.min(quantity, storeItem.stock);
                state.cart[itemIndex].quantity = maxQuantity; // Restrict to available stock
            }
        },
        toggleCartView: (state) => {
            state.isCartVisible = !state.isCartVisible;
        },
        updateStoreItems: (state, action) => {
            state.storeItems = action.payload;
        },
        cleanCart: (state) => {
            state.cart = state.cart
                .filter((cartItem) =>
                    state.storeItems.some((storeItem) => storeItem.id === cartItem.id)
                )
                .map((cartItem) => {
                    const storeItem = state.storeItems.find(
                        (storeItem) => storeItem.id === cartItem.id
                    );
                    return {
                        ...cartItem,
                        ...storeItem,
                        quantity: Math.min(cartItem.quantity, storeItem.stock), // Restrict to stock
                    };
                });
        },
        clearCart: (state) => {
            state.cart = [];
        },
        setMerchantMode: (state, action) => {
            state.merchantMode = action.payload;
        },
    },
});

export const {
    toggleItemInCart,
    updateItemQuantity,
    toggleCartView,
    updateStoreItems,
    cleanCart,
    clearCart,
    setMerchantMode,
} = merchantSlice.actions;


export const selectCart = (state) => state.merchantShop.cart;
export const selectCartVisibility = (state) => state.merchantShop.isCartVisible;
export const selectStoreItems = (state) => state.merchantShop.storeItems;
export const getMerchantMode = (state) => state.merchantShop.merchantMode;

export default merchantSlice.reducer;
