import { createSlice } from '@reduxjs/toolkit';

const accountSlice = createSlice({
    name: 'account',
    initialState: {
        accountInfo: {
            // this matches merchant (config.account) spreadsheet
            // TODO: website didn't render because these were null before redux if filled
            name: '',
            email: '',
            store: '',
        },
        loading: false,
        error: null,
        sheetID: null,
    },
    reducers: {
        loadData: (state, action) => {
            state.accountInfo = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setSheetID: (state, action) => {
            state.sheetID = action.payload;
        },
    },
});

export const {
    loadData,
    setLoading,
    setError,
    setSheetID,
} = accountSlice.actions;

export default accountSlice.reducer;
