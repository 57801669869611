import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./styles.module.css";

const Loading = () => {

    return (
        <div className={styles.loadingContainer}>
        <StaticImage
            src="../../images/icon_title.jpg"
            alt="w.xyz"
            className={styles.image}
        />
        </div>
    )
};

export default Loading;
