import { useEffect, useState } from "react";

const parseCSV = (csv, isArray = false) => {
    const rows = csv.trim().split("\n");

    return rows.map((row, rowIndex) => {
        const columns = row
            .match(/("(?:[^"]|"")*"|[^",\s]+)(?=\s*,|\s*$)/g) // Regex to split by commas while preserving quoted fields
            ?.map(col => col.replace(/^"|"$/g, "").replace(/""/g, '"').trim()); // Remove outer quotes and unescape inner quotes

        if (!columns) return isArray ? null : {};

        if (isArray) {
            // Handle key-value array parsing
            return columns.length < 2 ? null : [columns[0], columns[1]];
        }

        return columns;
    }).filter(Boolean); // Remove null rows
};

const parseCSVKeyValue = (csv) => {
    const parsedRows = parseCSV(csv, false);

    return parsedRows.reduce((acc, columns) => {
        const key = columns[0]; // First column as key
        if (!key) return acc; // Skip rows with no key

        const values = columns.slice(1).filter(value => value !== ""); // Remove empty strings
        acc[key] = values.length === 1 ? values[0] : values; // Single value or array
        return acc;
    }, {});
};

const parseCSVMenu = (csv) => {
    const rows = csv.trim().split("\n");

    const headers = rows.shift()?.match(/("(?:[^"]|"")*"|[^",\s]+)(?=\s*,|\s*$)/g)
        ?.map(h => h.replace(/^"|"$/g, "").replace(/""/g, '"')); // Parse headers

    if (!headers) return [];

    let previousValues = {
        category: null,
        menu: null,
        section: null,
        name: null,
        description: null,
        image: null
    };

    return rows.map(row => {
        const values = row.match(/("(?:[^"]|"")*"|[^",\s]+)(?=\s*,|\s*$)/g)
            ?.map(value => value.replace(/^"|"$/g, "").replace(/""/g, '"')); // Parse values

        const obj = {};
        headers.forEach((header, index) => {
            if (header) {
                let value = values?.[index] || null;

                // Auto-fill empty "category", "menu", "section", "name", "description", and "image" with the previous values
                // TODO: include description
                if (header.toLowerCase() === "category") {
                    obj[header] = value || previousValues.category;
                    previousValues.category = obj[header];
                    // Reset section when category changes
                    previousValues.section = value ? null : previousValues.section;
                } else if (header.toLowerCase() === "menu") {
                    obj[header] = value || previousValues.menu;
                    previousValues.menu = obj[header];
                } else if (header.toLowerCase() === "section") {
                    obj[header] = value || previousValues.section;
                    previousValues.section = obj[header]; // Update section value
                } else {
                    obj[header] = value;
                }
            }
        });
        return obj;
    });
};

const useKeyValue = ({ spreadsheetId, sheetName }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchSpreadsheetData = async () => {
            const url = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;

            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const csvText = await response.text();
                const jsonData = parseCSVKeyValue(csvText);

                setData(jsonData);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching spreadsheet data:", err);
                setError(err.message);
                setLoading(false);
            }
        };

        fetchSpreadsheetData();
    }, [spreadsheetId, sheetName]);

    return { loading, error, data };
};

const useKeyValueArray = ({ spreadsheetId, sheetName }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchSpreadsheetData = async () => {
            const url = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;

            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const csvText = await response.text();
                const jsonData = parseCSV(csvText, true);

                setData(jsonData);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching spreadsheet data:", err);
                setError(err.message);
                setLoading(false);
            }
        };

        fetchSpreadsheetData();
    }, [spreadsheetId, sheetName]);

    return { loading, error, data };
};

const useMenu = ({ spreadsheetId, sheetName }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchSpreadsheetData = async () => {
            const url = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;

            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const csvText = await response.text();
                const jsonData = parseCSVMenu(csvText);

                setData(jsonData);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching spreadsheet data:", err);
                setError(err.message);
                setLoading(false);
            }
        };

        fetchSpreadsheetData();
    }, [spreadsheetId, sheetName]);

    return { loading, error, data };
};

export {
    useKeyValue,
    useKeyValueArray,
    useMenu,
};
