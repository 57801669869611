import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from "./src/state/store";
import { useAccount } from "./src/state/useAccount";
import Loading from "./src/components/loading";
import config from "./config"

const LoadAccountProvider = ({ children }) => {

    const account = process.env.ACCOUNT;
    let accountObject = config.accounts[account]
    if (process.env.NODE_ENV === `development`) {
        console.log(`\n\n\naccount = ${JSON.stringify(account,null,2)}`)
        console.log(`\n\n\naccountObject = ${JSON.stringify(accountObject,null,2)}`)
    }
    const sheetID = accountObject.sheetID;
    const { loading, error } = useAccount(sheetID);

    if (loading) return <Loading />;
    if (error) return <div>Error loading account data: {error}</div>;

    return <>{children}</>;
};

export const wrapRootElement = ({ element }) => (
    <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
            <LoadAccountProvider>
                {element}
            </LoadAccountProvider>
        </PersistGate>
    </Provider>
);
