import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useKeyValue } from '../spreadsheet/useSheet';
import { loadData, setSheetID } from './accountSlice';

const useAccount = (sheetID) => {
    const dispatch = useDispatch();

    const accountInfo = useSelector((state) => state.account.accountInfo);

    const {
        loading: infoLoading,
        error: infoError,
        data: infoData,
    } = useKeyValue({
        spreadsheetId: sheetID,
        sheetName: "info",
    });

    useEffect(() => {
        if (infoData && !infoLoading && !infoError) {
            dispatch(loadData(infoData));
            dispatch(setSheetID(sheetID));
        }
    }, [accountInfo, infoData, infoLoading, infoError, dispatch]);

    return {
        loading: infoLoading,
        error: infoError,
        data: infoData,
    };
};

export { useAccount };
