import generateMenuHierarchy from './generateMenuHierarchy'
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	restaurantInfo: [],
	restaurantHours: [],
	menus: [],
	openedMenuIndex: 0,
	openedSectionIndex: 0,
	freshLoaded: true,
	hasOpened: false,
	toggleView: false,
}

const menuSlice = createSlice({
    name: "restaurant",
    initialState,
    reducers: {
		setInfoData: (state, action) => {
			state.restaurantInfo = Object.entries(action.payload);
		},
		setHoursData: (state, action) => {
			const transformedHours = action.payload.map(([day, hours]) => [day, hours]);
			state.restaurantHours = transformedHours;
		},
        setMenuData: (state, action) => {
            state.menus = generateMenuHierarchy(action.payload)
        },
        setOpenedMenu: (state, action) => {
            state.freshLoaded = true
            state.openedMenuIndex = action.payload
        },
        setOpenedSectionIndex: (state, action) => {
            state.freshLoaded = false
            state.openedSectionIndex = action.index
        },
        setHasOpened: (state) => {
            state.hasOpened = true
        },
		toggleView: (state) => {
            state.showMenu = !state.showMenu; // Toggle the view
        },
    },
});

export const {
    setInfoData,
    setHoursData,
    setMenuData,
    setOpenedMenu,
    setOpenedSectionIndex,
    setHasOpened,
	toggleView,
} = menuSlice.actions;

export default menuSlice.reducer;
